import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet'
import Banner from '../assets/images/ContactUs/Banner.webp'
import InquiryForm from '../components/InquiryForm';
import Phone from '../assets/images/ContactUs/Phone.webp';
import Email from '../assets/images/ContactUs/Email.webp';
import Location from '../assets/images/ContactUs/Location.webp';
import Facebook from '../assets/images/ContactUs/Facebook.webp';
import Twitter from '../assets/images/ContactUs/Twitter.webp';
import YouTube from '../assets/images/ContactUs/YouTube.webp';
import Background1 from '../assets/images/ContactUs/Background1.webp';
import Background2 from '../assets/images/ContactUs/Background2.webp';
import InquiryForm2 from '../components/InquiryForm2';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <React.Fragment>
            <Helmet>
                <title> First Pakistan Global </title>
            </Helmet>
            <Header />    
            <section id="website-responsive">
            <div className="text-center">
                        <div className="bg-common py-5" style={{backgroundImage:`url(${Banner})`}}>
                            <div className='text-white fs-ss-68 py-4 py-md-5 my-md-5'>
                            <div className='fw-700 text-uppercase'>
                                <div className="fw-700">
                                    Contact Us
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
                <div className="py-4 py-md-5" />
                <div id='aboutfpg' className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className="">
                        <h3 className='text-dark fs-ss-35 fw-600 pb-4 text-center'>
                            Get in <span className='text-ss-primary'> Touch </span>
                        </h3>
                        <div className="row justify-content-center px-md-5 gap-md-5 gy-5">
                        <div className="col-11 col-md-3 text-center text-ss-primary border-left-right">
                            <img src={Phone} className='w-icons' alt="" />
                            <div className="mt-md-3">
                            <a className="fs-ss-16 fw-600 text-dark text-decoration-none lh-sm" href="tel:2028694409">
                            (202) 869-4409
                            </a>
                            </div>                   
                            </div>

                            <div className="col-11 col-md-3 text-center text-ss-primary border-left-right">
                            <img src={Email} className='w-icons' alt="" />
                            <div className="mt-md-3">
                            <a className="fs-ss-16 fw-600 text-dark text-decoration-none lh-sm" href="mailto:info@firstpakistanglobal.org">
                            info@firstpakistanglobal.org
                            </a>
                            </div>                   
                            </div>

                            <div className="col-11 col-md-3 text-center text-ss-primary border-left-right">
                            <img src={Location} className='w-icons' alt="" />
                            <div className="mt-md-3">
                            <span className="fs-ss-16 fw-600 text-dark text-decoration-none lh-sm">
                            9890 Liberia Ave #1097 Manassas, <br className='' /> VA 20110 United States
                            </span>
                            </div>                   
                            </div>
        </div>
            </div>
            </div>
                </div>
                <div className="py-4 py-md-5" />
                <div className="container-fluid">
                <div className="row justify-content-center gap-4">
                <div className='col-11 col-md-4 bg-common py-5 px-3 shadow-ss-1' style={{backgroundImage:`url(${Background1})`}}>
                                    <div className='py-md-5'>
                                        <h3 className='text-white fs-ss-35 fw-600 text-center'>
                                            FPG Media Team
                                        </h3>
                                        <div className="fs-ss-18 fw-500 text-center mt-4 pt-3">
                                            <a href="mailto:media@firstpakistanglobal.org" className='text-decoration-none bg-border-white-hover text-white border-3 border-white border px-4 py-1 rounded-5'>
                                            media@firstpakistanglobal.org
                                            </a>
                                        </div>                
                            </div>
                            </div>
                            <div className='col-11 col-md-4 bg-common py-5 px-3 shadow-ss-1' style={{backgroundImage:`url(${Background1})`}}>
                                    <div className='py-md-5'>
                                        <h3 className='text-white fs-ss-35 fw-600 text-center'>
                                            FPG Inquiries
                                        </h3>
                                        <div className="fs-ss-18 fw-500 text-center mt-4 pt-3">
                                            <a href="mailto:info@firstpakistanglobal.org" className='text-decoration-none bg-border-white-hover text-white border-3 border-white border px-4 py-1 rounded-5'>
                                            info@firstpakistanglobal.org
                                            </a>
                                        </div>                
                            </div>
                            </div>
                </div>
                </div>

                            <div className="py-4 py-md-5" />     

                <div id='aboutfpg' className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className="">
                        <h3 className='text-dark fs-ss-35 fw-600 pb-3 pb-md-4 text-center'>
                            Follow <span className='text-ss-primary'> Us </span>
                        </h3>
                        <div className="row justify-content-center px-md-5">
                        <div className="text-center">
                        <a href="https://www.facebook.com/FirstPakistanGlobal" className='text-decoration-none' target='_blank'> <img src={Facebook} className='w-icons' alt="" /> </a>
                        <a href="https://x.com/FirstPakGlobal" className='text-decoration-none' target='_blank'> <img src={Twitter} className='w-icons mx-md-5' alt="" /> </a>
                        <a href="https://www.youtube.com/@FirstPakistanGlobal" className='text-decoration-none' target='_blank'> <img src={YouTube} className='w-icons' alt="" /> </a>         
                        </div>
        </div>
            </div>
            </div>
                </div>
                <div className="py-4 py-md-5" />
                <div className="bg-common" style={{backgroundImage:`url(${Background2})`}}>
                    <InquiryForm2/>
                </div>
                </section>
            <Footer />
        </React.Fragment>
    )
}

export default ContactUs
