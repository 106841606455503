import React from 'react'

const Space = (props) => {
    return (
    <>
    <div className={`${props.className}`}/>
    </>
    );
}

export default Space;
