import React from 'react'
import {PlayFill, TelephoneFill, EnvelopeFill, ChatLeftTextFill, GeoAltFill, Facebook, Instagram, Twitter, Youtube, X, TwitterX } from 'react-bootstrap-icons';
import logo from '../../assets/images/Home/logo.webp';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer id="website-responsive">
                <div className="footer-bg pt-md-5 pb-md-4" >
                    <div className='container-ss py-4'>
                        <div className='row py-4 gx-md-5'>
                        <div className='col-md-5 text-start'>                                
                            <img src={logo} className='img-fluid footer-logo bg-white p-2' alt='logo' />
                                <div className="fs-ss-14 text-white pe-md-5 lh-lg pt-md-3 fw-400">
                                First Pakistan Global (FPG) is a not for profit organization dedicated to safeguarding the rights and interests of Pakistanis across the globe. We advocate for democracy, justice, and human rights, ensuring that every Pakistani voice is heard and respected.
                                </div>
                            </div>
                            <div className='col-md-3 pt-md-0 pt-4'>                            
                                        <div className='text-open-sauce-one fs-ss-22 text-white lh-sm' style={{fontWeight:"600"}}>
                                            Quick Link
                                        <hr className='text-ss-primary col-3 border-3' style={{opacity:"1",width:"80px"}} />
                                        </div>
                                        {/* <hr className='border-ss-secondary-footer my-2'/> */}
                                        <ul className='list-unstyled mt-md-3'>
                                            <li><Link to='/about-fpg' className='text-decoration-none text-white lh-lg fs-ss-17'><PlayFill className='me-1'/> About FPG </Link></li>
                                            <li><Link to='/press-conferences' className='text-decoration-none text-white lh-lg fs-ss-17'><PlayFill className='me-1'/> Press Conferences </Link></li>
                                            <li><Link to='/events' className='text-decoration-none text-white lh-lg fs-ss-17'><PlayFill className='me-1'/> Events </Link></li>
                                            <li><Link to='/our-team' className='text-decoration-none text-white lh-lg fs-ss-17'><PlayFill className='me-1'/> Our Team </Link></li>
                                            <li><Link to='/contact-us' className='text-decoration-none text-white lh-lg fs-ss-17'><PlayFill className='me-1'/> Contact Us </Link></li>
                                            {/* <li><a href='#contact-us' className='text-decoration-none text-white lh-lg fs-ss-17'> <PlayFill className='me-1'/> Contact Us </a></li>
                                            <li><a href='#donate-now' className='text-decoration-none text-white lh-lg fs-ss-17'> <PlayFill className='me-1'/> Donate Now </a></li> */}
                                        </ul>                                
                            </div>
                            
                            <div className='col-md-4'>                                        
                                <div className='text-open-sauce-one fs-ss-22 text-white lh-sm' style={{fontWeight:"600"}}>
                                    Contact
                                <hr className='text-ss-primary border-3' style={{opacity:"1", width:"80px"}} />
                                </div>
                                            <ul className='list-unstyled mt-md-3 lh-lg'>
                                                {/* <li><a href='tel:0000000000' className='text-decoration-none text-white lh-lg '><TelephoneFill className='me-2' />Call us at: 
                                                000 000 0000 </a></li> */}
                                              <li className='mt-2'><a href='tel:2028694409' className='text-decoration-none text-white lh-lg '><TelephoneFill className='me-2' />
                                              (202) 869-4409 </a></li>                                                
                                                <li><a href='mailto:info@firstpakistanglobal.org' className='text-decoration-none text-white lh-lg '><EnvelopeFill className='me-2' />
                                                info@firstpakistanglobal.org </a></li>
                                                <li className='d-flex lh-sm mt-2'> 
                                                <div className="">
                                                    <GeoAltFill className='me-2 text-white' />
                                                </div>
                                                <div className="">
                                                <span className='text-decoration-none text-white '> 9890 Liberia Ave #1097 Manassas, VA 20110 United States </span>
                                                </div>
                                                </li>
                                            </ul>
                                            <div className="fs-ss-22 text-white fw-600">
                                    Follow Us
                                </div>
                                <div className="fs-ss-26 text-white fw-600">
                                    <a target='_blank' className='text-decoration-none text-white' href="https://www.facebook.com/FirstPakistanGlobal"> <Facebook className='me-3'/> </a> 
                                    <a target='_blank' className='text-decoration-none text-white' href="https://x.com/FirstPakGlobal">  <TwitterX className='me-3'/> </a>
                                    <a target='_blank' className='text-decoration-none text-white' href="https://www.youtube.com/@FirstPakistanGlobal">  <Youtube className=''/> </a>
                                </div>
                                    </div>
                        </div>
                        
                    </div>
                </div>
                <div className='py-2'>
                    <div className='container-zk'>
                        <div className='text-center'>
                            <p className='p-0 m-0 text-dark fs-ss-14 px-md-0 px-2'>
                           Copyright © 2025 First Pakistan Global. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
