import React from 'react'
import {Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.css'
import './assets/css/style2.css'
import 'react-toastify/dist/ReactToastify.css';
import AboutFPG from './pages/AboutFPG';
import OurTeam from './pages/OurTeam';
import Initiatives from './pages/Initiatives';
import BecomeAMember from './pages/BecomeAMember';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import AdvocacyAndEngagement from './pages/AdvocacyAndEngagement';
import PressConferences from './pages/PressConferences';
import Events from './pages/Events';

const App = () => {
    return (
        <React.Fragment>
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-fpg" element={<AboutFPG />} />
                <Route path="/our-team" element={<OurTeam />} />
                <Route path="/advocacy-and-engagement" element={<AdvocacyAndEngagement />} />
                <Route path="/press-conferences" element={<PressConferences />} />
                <Route path="/events" element={<Events />} />
                <Route path="/contact-us" element={<ContactUs />} />
                {/* <Route path="/become-a-member" element={<BecomeAMember />} /> */}
                </Routes>
        </React.Fragment>
    );
}

export default App;
