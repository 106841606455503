import React from "react";
import {Link, useLocation} from "react-router-dom";

const NavBarLink2 = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = useLocation;
  return (
    <>
    <li className="nav-item hover-ss-white right-menu d-block d-md-none fs-ss-15">
          <div className="btn-group dropdown">
          <span className={splitLocation[1] === `${props.Link_To}` ? `nav-link hover-ss-white fw-600 hover-ss-primary ${props.Border}` : `nav-link hover-ss-white fw-600 hover-ss-primary ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </span>

            <Link to="" className="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-dark" data-bs-toggle="dropdown-menu" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
              </Link>
          </div>
          <ul className="dropdown-menu w-content p-3 bg-ss-primary-op">
            {props.DropdownMenu}
          </ul>
    </li>
    
    <li className="nav-item hover-ss-white right-menu d-none d-md-block me-lg-3 pt-1 fs-ss-15">
          <span className={splitLocation[1] === `${props.Link_To}` ? `nav-link dropdown-toggle hover-ss-white fw-600 hover-ss-primary ${props.Border}` : `nav-link dropdown-toggle hover-ss-white fw-600 hover-ss-primary ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </span>
          <ul className="dropdown-menu w-content px-2 py-3">
            {props.DropdownMenu}
          </ul>
    </li>
    </>
  )
}

export default NavBarLink2