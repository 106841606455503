import React, { useState, useEffect } from 'react';
import Space from './Space';
import Slider from 'react-slick';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

const InitiativesCommon = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [selectedImage, setSelectedImage] = useState(''); // State for the selected image URL
  const [modalContent, setModalContent] = useState({
    title: '',
    subtitle: '',
    para: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Open the modal with the selected image and associated content
  const openModal = (image, title, subtitle, para) => {
    setSelectedImage(image);
    setModalContent({ title, subtitle, para });
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <>
    <section id="website-responsive" className="container-fluid px-0 mb-md-5 pb-md-5">
      <div className="col-12 d-md-flex justify-content-md-start justify-content-center align-items-center gap-4 border-mobile mt-4 mt-md-0">
        <div className="col-11 col-md-2 fs-ss-35 fw-700 border-4 border-end border-ss-primary-end text-center text-uppercase py-md-5 mb-4 mb-md-0 mx-auto">
          {props.Date}
        </div>
        <div className="col-11 col-md-3 mx-auto">
          <Slider {...settings} className="mb-5 mb-md-0">
            {props.Images &&
              props.Images.map((image, index) => (
                <div key={index} className="image-container p-2 ">
                  <img
                    src={image}
                    className="zoom-on-hover w-image cursor-zoom-in"
                    alt={`Image ${index}`}
                    data-bs-toggle="modal" 
                    data-bs-target={`#${props.Id}`}
                  />
                </div>
              ))}
          </Slider>
        </div>
        <div className="col-11 col-md-6 pe-md-5 mb-4 mb-md-0 mx-auto">
          <div className="fs-ss-26 fw-600 lh-sm hover-ss-primary cursor-pointer" data-bs-toggle="modal" data-bs-target={`#${props.Id}`} >{props.Title}</div>
          <Space className="py-2" />
          <div className="fs-ss-18 fw-600 text-ss-primary lh-sm">{props.SubTitle}</div>
          <Space className="py-2" />
          <div className="fs-ss-16 fw-400 lh-para">{props.Para}</div>
        </div>
      </div>

      <div className="modal modal-xl fade pb-4" id={props.Id} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content bg-grey gy-0">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body row justify-content-start align-items-start pb-5 ms-md-3">
            <div className={`${props.ImgCol} ${props.ImageDisplay} me-auto`}>
          <Slider {...settings} className="mb-3 mb-md-5">
            {props.Images &&
              props.Images.map((image, index) => (
                <div key={index} className="image-container">
                  <img
                    src={image}
                    className=""
                    alt={`Image ${index}`}
                  />
                </div>
              ))}
          </Slider>
        </div>
              <div className={`${props.ImgCol} ${props.VideoDisplay} mx-auto `}>
        <iframe className='image-container mb-md-5' height={500} src={`${props.VideoLink}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen data-bs-toggle="modal" 
        data-bs-target={props.Id}/>
              </div>
              <div id='clinical-team' className="col-11 lh-sm pt-5 pt-lg-0">
                <div className="fs-ss-26 fw-600 lh-sm">{props.Title}</div>
                <Space className="py-2" />
                <div className="fs-ss-18 fw-600 text-ss-primary lh-sm">{props.SubTitle}</div>
                <Space className="py-2" />
                <div className="fs-ss-16 fw-400 lh-para">{props.Para}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default InitiativesCommon;
