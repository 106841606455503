import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../assets/images/Advocacy/Banner.webp';
import Event1Img1 from '../assets/images/Advocacy/A1/Event1Img1.webp';
import Event1Img2 from '../assets/images/Advocacy/A1/Event1Img2.webp';
import Event2Img1 from '../assets/images/Advocacy/A2/Event2Img1.jpg';
import Event2Img2 from '../assets/images/Advocacy/A2/Event2Img2.jpg';
import Event2Img3 from '../assets/images/Advocacy/A2/Event2Img3.jpg';
import Event2Img4 from '../assets/images/Advocacy/A2/Event2Img4.jpg';
import Event2Img5 from '../assets/images/Advocacy/A2/Event2Img5.jpg';
import Event2Img6 from '../assets/images/Advocacy/A2/Event2Img6.jpg';
import Event2Img7 from '../assets/images/Advocacy/A2/Event2Img7.jpg';
import Event3Img1 from '../assets/images/Advocacy/A3/Event3Img1.jpeg';
import Event4Img1 from '../assets/images/Advocacy/A4/Event4Img1.jpg';
import Event5Img1 from '../assets/images/Advocacy/A5/Event5Img1.jpg';
import Event6Img1 from '../assets/images/Advocacy/A6/Event6Img1.jpg';
// import Event7Img1 from '../assets/images/Advocacy/A8/Event8Img1.jpeg';
import Event8Img1 from '../assets/images/Advocacy/A8/Event8Img1.jpeg';
import Event9Img1 from '../assets/images/Advocacy/A9/Event9Img1.jpg';
import Event9Img2 from '../assets/images/Advocacy/A9/Event9Img2.jpg';
import Event9Img3 from '../assets/images/Advocacy/A9/Event9Img3.jpg';
import Event9Img4 from '../assets/images/Advocacy/A9/Event9Img4.jpg';
import Event10Img1 from '../assets/images/Advocacy/A10/Event10Img1.jpg';
import Event11Img1 from '../assets/images/Advocacy/A11/Event11Img1.jpg';
import Event11Img2 from '../assets/images/Advocacy/A11/Event11Img2.jpg';
import Event12Img1 from '../assets/images/Advocacy/A12/Event12Img1.jpg';
import Event13Img1 from '../assets/images/Advocacy/A13/Event13Img1.jpg';
import Event14Img1 from '../assets/images/Advocacy/A14/Event14Img1.jpg';
import Event15Img1 from '../assets/images/Advocacy/A15/Event15Img1.jpg';
import InitiativesCommon from '../components/common/InitiativesCommon';

const AdvocacyAndEngagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>First Pakistan Global</title>
      </Helmet>
      <Header />
      <section id="about-page">
        <div className="text-center">
          <div className="bg-common py-5" style={{ backgroundImage: `url(${Banner})` }}>
            <div className='text-white fs-ss-68 py-4 py-md-5 my-md-5'>
              <div className='fw-700 text-uppercase'>
                <div className="fw-700">
                  Advocacy and Engagement
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 py-md-5" />
        <section className="container-fluid">
          <div className="row justify-content-center gy-5">
          <InitiativesCommon
              Id="1"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10 "
              Date={<> Nov <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event1Img1, Event1Img2]}
              Title="Round Table discussion with Lara Trump"
              SubTitle="Co-Chair of the Republican National Committee"
              Para="Policy Round Table Discussion with Lara Trump, co-Chair, Republican National Committee (RNC), and members of Pakistani American Physicians Association and First Pakistan Global. Updated Lara Trump on the current situation in Pakistan and discussed future democratic relationship of the two countries. "
            />

            <InitiativesCommon
              Id="2"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 23 <br className='d-none d-md-block' /> Oct <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event2Img1, Event2Img2, Event2Img3, Event2Img4, Event2Img5, Event2Img6, Event2Img7]}
              Title="Congressional Letter to President Biden"
              SubTitle="FPG supported other diaspora organizations to initiate the letter"
              Para="Over 62 members of Congress have signed a powerful letter demanding sanctions, asset freezes, and travel bans on top military officials, including Asim Munir, for their involvement in human rights abuses, election rigging, and corruption. The letter also calls for the immediate release of political prisoners, including Former PM Imran Khan, and urges for his fair trial based on justice. "
            />  

            <InitiativesCommon
              Id="3"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> June <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event3Img1]}
              Title="Advocacy for Democracy in Pakistan"
              SubTitle="Congresswoman Spanberger's Efforts to Support Pakistani People"
              Para="FPG engaged Congresswoman Abigail Spanberger and provided updates on the ongoing human rights situation in Pakistan. Congresswoman Spanberger wrote a letter to Secretary Blinken urging him to support democracy and the will of Pakistani people."
            />  
            
            <InitiativesCommon
              Id="4"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 26 <br className='d-none d-md-block' /> Nov <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event4Img1]}
              VideoLink="https://www.youtube.com/embed/qtRnIK_DXUk?si=oSfaYiHzIp25fBBC"
              Title="All American Talk Show"
              SubTitle="Islamabad Massacre"
              Para="Dr. Usman Malik and Dr. Saira Bilal, briefed the audience on the events of November 26, 2024 in Islamabad, aka “Islamabad Massacre.” "
            /> 

            <InitiativesCommon
              Id="5"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> Nov <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event5Img1]}
              VideoLink="https://www.youtube.com/embed/oqQT9ZHg-NE?si=Lk_dGfWW3v3f51sQ"
              Title="All American Talk Show"
              SubTitle="Muslim Leaders' Support for President Trump"
              Para="Ground-breaking discussion led by Dr. Abbas and Dr. Saira Bilal regarding endorsement of President Trump by Muslim leaders."
            /> 

            {/* <InitiativesCommon
              Id="6"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event6Img1]}
              Title="Engaged with Legislators at the Hill"
              SubTitle=""
              Para=""
            />  */}

            {/* <InitiativesCommon
              Id="7"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 26 <br className='d-none d-md-block' /> Nov <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event7Img1]}
              VideoLink=""
              Title=""
              SubTitle=""
              Para="Email Campaign for IHC Judges demanding the full investigation of harassment and intimidation by security forces (attach the Judges letter and the press release or the letter to the Congress)."
            />  */}
          
            
          <InitiativesCommon
              Id="8"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 10 <br className='d-none d-md-block' /> Feb <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event8Img1]}
              Title="White Paper"
              SubTitle=""
              Para="Official copy of white paper issued by First Pakistan Global highlighting the role of Pakistani military establishment and unscrupulous officials in election rigging and human rights violations."
            /> 
            
            <InitiativesCommon
              Id="9"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> Nov <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event9Img1, Event9Img2, Event9Img3, Event9Img4]}
              Title="Engaged with Republican Candidates"
              SubTitle=""
              Para=""
            /> 
            
            <InitiativesCommon
              Id="10"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event10Img1]}
              Title="Advocacy with Congresswoman Abigail Spanberger"
              SubTitle=""
              Para="FPG members at grassroot level engaging with Congresswoman Abigail Spanberger. FPG briefed her about the ongoing human rights violations in Pakistan  and transnational repression."
            /> 
            
            <InitiativesCommon
              Id="11"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event11Img1, Event11Img2]}
              Title="Engaged with Jeremy McPike"
              SubTitle=""
              Para=""
            /> 
            
            <InitiativesCommon
              Id="12"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event12Img1]}
              Title="Day at the Hill"
              SubTitle=""
              Para="FPG members along with other local organizations engaged with various legislative offices updating them about the current state of affairs in Pakistan."
            /> 
            
            <InitiativesCommon
              Id="13"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event13Img1]}
              Title="Engaged with Scott Perry and Andy Harris"
              SubTitle=""
              Para="Briefed the legislators regarding the current human rights violations and political unrest in Pakistan."
            /> 
            
            <InitiativesCommon
              Id="14"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event14Img1]}
              Title="Engaged with Bob Menendez"
              SubTitle=""
              Para=""
            /> 
            
            <InitiativesCommon
              Id="15"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event15Img1]}
              Title="Engaged with Senator Chris Van Hollen"
              SubTitle=""
              Para=""
            />          
          </div>
        </section>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default AdvocacyAndEngagement;
