import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet'
import Banner from '../assets/images/OurTeam/Banner.webp'
import Img1 from '../assets/images/Home/Img1.jpg'

const OurTeam = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <React.Fragment>
            <Helmet>
                <title> First Pakistan Global </title>
            </Helmet>
            <Header />   
            <div id='website-responsive'>
            <div className="fs-ss-30 text-dark text-center fw-500 border-top ">
                <div className="py-5 my-md-5">
                    Coming Soon!
                </div>
            </div>     
            </div>  
            <Footer />
        </React.Fragment>
    )
}

export default OurTeam
