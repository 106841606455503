import React, { Component } from "react";
import Slider from "react-slick";
import Img1 from "../assets/images/Home/22jan-img1.jpg";
import Img2 from "../assets/images/Home/22jan-img2.jpg";
import Img3 from "../assets/images/Home/22jan-img3.jpg";
import Img4 from "../assets/images/Home/22jan-img4.jpg";
import Img5 from "../assets/images/Home/Img5.png";
import Img6 from "../assets/images/Home/Img6.jpg";
import Img7 from "../assets/images/Home/Img7.jpg";
import Img8 from "../assets/images/Home/Img8.jpg";
import Img9 from "../assets/images/Home/Img9.jpg";
import Img10 from "../assets/images/Home/Img10.jpg";
import Img11 from "../assets/images/Home/Img11.jpg";
import Img12 from "../assets/images/Home/Img12.jpg";
import Img13 from "../assets/images/Home/Img13.jpg";
import Img14 from "../assets/images/Home/Img14.jpg";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
        <Slider {...settings} className="mb-3 mb-md-0">
          <iframe id="ss-iframe" src="https://drive.google.com/file/d/18RY8BUtuZisMoZimYf_nsTqnP123MwTe/preview" width="100%" height="315" allow="autoplay" className="shadow-ss-1"></iframe>
          {/* <iframe src="https://drive.google.com/file/d/18RY8BUtuZisMoZimYf_nsTqnP123MwTe/preview" width="100%" height="340" allow="autoplay"></iframe> */}
          <img src={Img1} className="d-block w-100 mx-auto shadow-ss-1 mb-md-3" alt="000"/>
          <img src={Img2} className="d-block w-100 mx-auto shadow-ss-1 mb-md-3" alt="000"/>
          <img src={Img3} className="d-block w-100 mx-auto shadow-ss-1 mb-md-3" alt="000"/>
          <img src={Img4} className="d-block w-100 mx-auto shadow-ss-1 mb-md-3" alt="000"/>
          {/* <img src={Img5} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img6} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img7} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img8} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img10} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img11} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img12} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img13} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/>
          <img src={Img14} className="d-block w-90 mx-auto shadow-ss-1 mb-md-4" alt="000"/> */}
        </Slider>
    );
  }
}