import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../assets/images/PressConferences/Banner.webp';
import Event1Img1 from '../assets/images/PressConferences/B1/Event1Img1.jpeg';
import Event2Img1 from '../assets/images/PressConferences/B2/Event2Img1.jpg';
import Event3Img1 from '../assets/images/PressConferences/B3/congressional-briefing-img1.jpg';

import InitiativesCommon from '../components/common/InitiativesCommon';

const PressConferences = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>First Pakistan Global</title>
      </Helmet>
      <Header />
      <section id="about-page">
        <div className="text-center">
          <div className="bg-common py-5" style={{ backgroundImage: `url(${Banner})` }}>
            <div className='text-white fs-ss-68 py-4 py-md-5 my-md-5'>
              <div className='fw-700 text-uppercase'>
                <div className="fw-700">
                  Press Conferences
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 py-md-5" />

        <section className="container-fluid">
          <div className="row justify-content-center gy-5">
          <InitiativesCommon
              Id="3"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event3Img1]}
              // VideoLink="https://www.youtube.com/embed/rNCICSEYzCw?si=0k8qvU6I-0TFDkGk"
              Title={<>Congressional Briefing</>}
              SubTitle="Key Points:"
              Para={<> 
              <ul>
                <li> The massacre of peaceful protesters through live gunfire. </li> 
                <li> The suppression of free speech and dissent under undeclared martial law. </li>
                <li> Calls for sanctions against those responsible for human rights violations. </li>
                <li> Special thanks to Congressmen Greg Casar, Jim McGovern, August Pfluger, Frank Pallone, Rashida Tlaib, Eugene Vindman, and over 25 Congressional staffers for their support. </li>
              </ul> 
              </>}
              />

          <InitiativesCommon
              Id="1"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 10 <br className='d-none d-md-block' /> Dec <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event1Img1]}
              VideoLink="https://www.youtube.com/embed/rNCICSEYzCw?si=0k8qvU6I-0TFDkGk"
              Title={<>Press Conference on Islamabad Massacre, <br className="d-block-d-md-none" /> National Press Club, Washington DC</>}
              SubTitle="Key Demands:"
              Para={<> 
              <ul>
                <li> Immediate resignation of ALL THE PERPETRATORS, including General Asim Munir and abetters for orchestrating the brutal crackdown on peaceful pro-democracy civilians. </li> 
                
                <li> UN International Inquiry: To investigate abduction and forced disappearances of civilians. </li>
                
                <li> Travel bans, visa restrictions, and asset freezes for perpetrators of crimes against humanity.  </li>
              </ul> 
              </>}
              />

            <InitiativesCommon
              Id="2"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 19 <br className='d-none d-md-block' /> March <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event2Img1]}
              VideoLink="https://www.youtube.com/embed/T5OW9usXBM0?si=zpNGGyT9UcVZlVbQ"
              Title="Press Conference - Democracy and Human Rights"
              SubTitle=""
              Para="FPG in collaboration with the Pakistani-American diaspora held a press conference at the National Press Club on March 19, 2024 to inform the American media and U.S. policymakers about the stolen mandate of Pakistani people on February 8, 2024."
            />  
          </div>
        </section>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default PressConferences;
