import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet'
import Banner from '../assets/images/AboutUs/Banner.webp'

const AboutFPG = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <React.Fragment>
            <Helmet>
                <title> First Pakistan Global </title>
            </Helmet>
            <Header />    
            <section id="about-page">
            <div className="text-center">
                        <div className="bg-common py-5" style={{backgroundImage:`url(${Banner})`}}>
                            <div className='text-white fs-ss-68 py-4 py-md-5 my-md-5'>
                            <div className='fw-700 text-uppercase'>
                                <div className="fw-700">
                                    About FPG
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
                <div className="py-4 py-md-5" />
            <div id='aboutfpg' className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className="col-11 col-md-10">
                        <h3 className='text-dark fs-ss-35 fw-600 pb-2 pb-md-4 text-md-center'>
                        <span className='text-ss-primary'> FPG History </span>  and Background Information 
                    </h3>
            <div className='text-poppins fs-ss-16 lh-lg fw-400'>
            As Pakistani Americans, we never thought we would be part of an advocacy group pushing for democracy and human rights in Pakistan. Like many of our fellow Pakistani-Americans, we focused on our professions and family lives, grateful for the opportunities afforded to us in the United States. However, the alarming deterioration of democratic institutions and civil liberties in Pakistan since spring 2022 has compelled many of us to take action. From this deep concern, we organized a grassroots group known as First Pakistan Global (FPG), which, from humble beginnings, has become a dynamic force for change. 
            <div className="py-1" />
            FPG was born out of necessity. Our group consists of people from all walks of life—physicians, IT professionals, engineers, entrepreneurs, and community leaders—who came together with one common goal: to give back to society and push for positive change in Pakistan. Our initial efforts were modest, but momentum built quickly. We began with community fundraisers, gatherings, and educational talks, evolving into an organized force advocating for human rights, civil supremacy, and democracy in Pakistan. 
            </div>
            </div>
            </div>
                </div>
                <div className="py-4 py-md-5" />
                <div className='container-fluid bg-middle-banner py-5 px-3'>
                    <div className='py-md-5'>
                        <h3 className='text-white fs-ss-52 fw-600 text-center'>
                            Join Us
                        </h3>
                        <div className="text-white fw-500 fs-ss-22 text-center mt-4">
                            Human Rights Movement Needs You!
                        </div>
                        <div className="fs-ss-20 fw-500 text-center mt-5">
                            <Link to="/contact-us" className='text-decoration-none bg-border-white-hover text-white border-3 border-white border px-4 py-2 rounded-5'>
                            Contact Us
                            </Link>
                        </div>                
            </div>
            </div>
            <div className="py-4 py-md-5" />
            <div id='aboutfpg' className='container-fluid'>
                    <div className='row justify-content-center align-items-center'>
                        <div className="col-11 col-md-4">
                        <h3 className='text-dark fs-ss-40 fw-600 pb-2 pb-md-4'>
                        FPG Story <br className='d-none d-md-block' /> Behind the <br className='d-none d-md-block' /> <span className='text-ss-primary'> Advocacy Group </span> 
                    </h3>
                        </div>
                        <div className="col-11 col-md-6">
            <div className='text-poppins fs-ss-16 lh-lg fw-400'>
            At its core, FPG reflects the Pakistani-American community’s shared concern for Pakistan’s future. What started as informal discussions in living rooms and community centers has blossomed into a well-coordinated movement. We began with simple events—fundraisers for charities, community picnics to foster engagement, and panel discussions at universities about the political and economic crises facing Pakistan. Over time, our efforts expanded to organizing protests at institutions like the International Monetary Fund (IMF), sit-ins at the State Department in support of democracy and just foreign policy, and advocating for the U.S. government to take a stronger stance on Pakistan’s deteriorating democratic conditions. 
            </div>
            </div>
            </div>
                </div>
                <div className="py-4 py-md-5" />
                </section>
            <Footer />
        </React.Fragment>
    )
}

export default AboutFPG
