import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../assets/images/Events/Banner.webp';
import Event1Img1 from '../assets/images/Events/C1/Event1Img1.jpeg';
import Event1Img2 from '../assets/images/Events/C1/Event1Img2.jpeg';
import Event1Img3 from '../assets/images/Events/C1/Event1Img3.jpeg';
import Event2Img1 from '../assets/images/Events/C2/Event2Img1.jpeg';
import Event2Img2 from '../assets/images/Events/C2/Event2Img2.jpeg';
import Event2Img3 from '../assets/images/Events/C2/Event2Img3.jpeg';
import Event3Img1 from '../assets/images/Events/C3/Event3Img1.jpeg';
import Event3Img2 from '../assets/images/Events/C3/Event3Img2.jpeg';
import Event3Img3 from '../assets/images/Events/C3/Event3Img3.jpeg';
import Event3Img4 from '../assets/images/Events/C3/Event3Img4.jpeg';
import Event4Img1 from '../assets/images/Events/C4/Event4Img1.jpg';
import Event5Img1 from '../assets/images/Events/C5/Event5Img1.jpeg';
import Event5Img2 from '../assets/images/Events/C5/Event5Img2.jpeg';
import Event5Img3 from '../assets/images/Events/C5/Event5Img3.jpeg';
import Event5Img4 from '../assets/images/Events/C5/Event5Img4.jpeg';
import Event7Img1 from '../assets/images/Events//C7/Event7Img1.jpeg';
import Event7Img2 from '../assets/images/Events//C7/Event7Img2.jpeg';
import Event7Img3 from '../assets/images/Events//C7/Event7Img3.jpeg';
import Event7Img4 from '../assets/images/Events//C7/Event7Img4.jpeg';
import Event8Img1 from '../assets/images/Events/C8/22jan-img1.jpg';
import Event8Img2 from '../assets/images/Events/C8/22jan-img2.jpg';
import Event8Img3 from '../assets/images/Events/C8/22jan-img3.jpg';
import Event8Img4 from '../assets/images/Events/C8/22jan-img4.jpg';

import InitiativesCommon from '../components/common/InitiativesCommon';

const Events = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>First Pakistan Global</title>
      </Helmet>
      <Header />
      <section id="about-page">
        <div className="text-center">
          <div className="bg-common py-5" style={{ backgroundImage: `url(${Banner})` }}>
            <div className='text-white fs-ss-68 py-4 py-md-5 my-md-5'>
              <div className='fw-700 text-uppercase'>
                <div className="fw-700">
                  Events
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 py-md-5" />
        <section className="container-fluid">
          <div className="row justify-content-center gy-5">

          <InitiativesCommon
              Id="8"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event8Img1, Event8Img2, Event8Img3, Event8Img4]}
              Title="Congressional Briefing"
              SubTitle=""
              Para="On January 22, 2025, First Pakistan Global, Congressman Greg Casar's office, and coalition organizations hosted a pivotal Congressional."
            />

          <InitiativesCommon
              Id="1"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event1Img1, Event1Img2, Event1Img3]}
              Title="Summit at Capital Hill"
              SubTitle=""
              Para="Human Rights Conference with Congressman Greg Casar and Congresswoman Abigail Spanberger."
            />

            <InitiativesCommon
              Id="2"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 29 <br className='d-none d-md-block' /> Sep <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event2Img1, Event2Img2, Event2Img3, Event3Img4]}
              Title="Conference at University of Central Florida (UCF)"
              SubTitle="FPG supported other diaspora organizations to initiate the letter"
              Para="To address human rights violations & political instability in Pakistan, September 29, 2024. First Pakistan Global and Pakistani-American community held a conference at University of Central Florida (UCF) to address human rights violations and political instability in Pakistan.  A poll showed 63% of attendees held General Asim Munir responsible for the socio-economic and political crisis in Pakistan, demanding his resignation for violating his oath.  "
            />  

            <InitiativesCommon
              Id="3"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 14 <br className='d-none d-md-block' /> March <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event3Img1, Event3Img2, Event3Img3, Event3Img4]}
              Title="IMF Protest"
              SubTitle=""
              Para="FPG, in collaboration with the Pakistani-American diaspora organized the protest in front of the IMF demanding independent and transparent investigation by International Observers and fact finding committee to any loan disbursement to the illegitimate regime in Pakistan."
            />  
            
            <InitiativesCommon
              Id="4"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 30 <br className='d-none d-md-block' /> Dec <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event4Img1]}
              Title="Global Conference on Human Rights"
              SubTitle=""
              Para="FPG in collaboration with Friends of Democratic Pakistan held Global Conference on Human Rights and Diaspora’s role in demanding democracy in Pakistan–Gaylord Convention Center, DC, USA."
            />  
            
            {/* <InitiativesCommon
              Id="5"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event5Img1, Event5Img2, Event5Img3, Event5Img4]}
              Title="George Washington University Conference"
              SubTitle=""
              Para=""
            />  */}

            <InitiativesCommon
              Id="7"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 02 <br className='d-none d-md-block' /> Nov <br className='d-none d-md-block' /> 2023 </>}
              Images={[Event7Img1, Event7Img2, Event7Img3, Event7Img4]}
              Title="Hunger Strike event at the State Dept"
              SubTitle=""
              Para=""
            /> 
          </div>
        </section>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Events;
