import React from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../assets/images/Home/logo.webp';
import { Facebook, Twitter, TwitterX, X } from 'react-bootstrap-icons';
import NavBarLink2 from '../NavBarLink2';


const Header = () => {
    const location = useLocation();
    const { pathname } = location;
    
    const splitLocation = pathname.split("/");

    return (
        <>
        <Navbar expand="xl" className="bg-white py-1 px-md-5 mx-md-5" id="website-nav">
            <Container fluid className='px-md-5'>
                <Navbar.Brand><Link
                className="flex-wrap navbar-brand d-flex align-items-baseline"
                to="/"
                >
                <img
                    src={logo}
                    alt="Logo"
                    className="img-fluid logo-img"
                />
                </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
                <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-xl`}
                aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                placement="start"
                className=""
                >
                <Offcanvas.Header closeButton className='pb-0'>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                        <Link
                        className="flex-wrap navbar-brand d-flex align-items-baseline"
                        to="/"
                        >
                        <img
                            src={logo}
                            alt="Logo"
                            className="py-2 img-fluid"
                            style={{height: "60px"}}
                        />

                        </Link>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                {/* <hr /> */}
                <Offcanvas.Body>
                    <Nav className="d-none d-md-block ms-md-4 justify-content-start flex-grow-1 text-raleway website-np align-items-nav">
                    {/* <a to="#aboutfpg" className={splitLocation[1] === "" ? "active py-2 fs-ss-15 nav-link text-raleway hover-ss-primary pt-1" : "py-2 fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> About FPG </a>
                        <a to="#initiatives" className={splitLocation[1] === "features" ? "active py-2 fs-ss-15 nav-link text-raleway hover-ss-primary pt-1" : "py-2 fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> Initiatives </a>
                        <a to="#core-values" className={splitLocation[1] === "faqs" ? "active py-2 fs-ss-15 nav-link text-raleway hover-ss-primary pt-1" : "py-2 fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> Core Values </a>  */}
                    </Nav>

                    <Nav className='align-items-md-center'>
                    <Link to="/" className={splitLocation[1] === "" ? "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1 text-ss-primary" : "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> Home </Link>
                    <Link to="/about-fpg" className={splitLocation[1] === "about-fpg" ? "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1 text-ss-primary" : "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> About FPG </Link>
                    <Link to="/our-team" className={splitLocation[1] === "our-team" ? "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1 text-ss-primary" : "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> Our Team </Link>
                    <NavBarLink2
                    Name="Initiatives"
                    Link_To="initiatives"
                    DropdownMenu={
                        <>
                    <Link to="/advocacy-and-engagement" className={splitLocation[1] === "advocacy-and-engagement" ? "fs-ss-15 nav-link text-raleway hover-ss-primary text-ss-primary" : "fs-ss-15 nav-link text-raleway hover-ss-primary"} style={{fontWeight:"600"}}> Advocacy & Engagement </Link>
                    <Link to="/events" className={splitLocation[1] === "events" ? "fs-ss-15 nav-link text-raleway hover-ss-primary text-ss-primary my-md-2" : "fs-ss-15 nav-link text-raleway hover-ss-primary my-md-2"} style={{fontWeight:"600"}}> Events </Link>                        
                    <Link to="/press-conferences" className={splitLocation[1] === "press-conferences" ? "fs-ss-15 nav-link text-raleway hover-ss-primary text-ss-primary" : "fs-ss-15 nav-link text-raleway hover-ss-primary"} style={{fontWeight:"600"}}> Press Conferences </Link>    
                        </>
                    }
                    />
                        {/* <Link to="/become-a-member" className={splitLocation[1] === "become-a-member" ? "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1 text-ss-primary" : "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> Become a Member </Link>  */}
                        <Link to="/contact-us" className={splitLocation[1] === "contact-us" ? "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1 text-ss-primary" : "fs-ss-15 nav-link text-raleway hover-ss-primary pt-1"} style={{fontWeight:"600"}}> Contact Us </Link> 
                        
                    {/* <div className="fs-ss-26 text-white fw-600 pt-3 pt-md-0">
                        <a target='_blank' className='text-decoration-none text-ss-primary' to="https://www.facebook.com/FirstPakistanGlobal"> <Facebook className='me-3 '/> </a> 
                        <a target='_blank' className='text-decoration-none text-ss-primary' to="https://x.com/FirstPakGlobal">  <TwitterX className='me-3'/> </a>
                    </div> */}
                    </Nav>
                    
                </Offcanvas.Body>
                </Navbar.Offcanvas>
                
            </Container>
        </Navbar>
        </>
    )
}

export default Header